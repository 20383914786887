import * as YAML from "yaml";
import merge from "lodash/merge";
import { v4 as uuid } from "uuid";

import { QUOTAS_FIELDS } from "utils/constants/nested-clusters-config";
import { parseYAMLValues } from "utils/parsers";
import { getTerminalKeys } from "./objects";

function extractComments(doc) {
  const comments = {};
  function commentsFromItems(items = [], prefixKey = "") {
    items.forEach((item, index) => {
      if (!item?.key && !prefixKey) {
        return;
      }

      // needed for an item that has an array as value
      if (!item?.key && prefixKey) {
        commentsFromItems(item?.items || [], `${prefixKey}.${index}`);
        return;
      }

      const key = prefixKey
        ? `${prefixKey}.${item?.key?.value}`
        : item.key.value;

      let type = item?.value?.type;
      if (YAML.isSeq(item.value)) {
        type = "SEQ";
        if (item.value.flow) {
          type = "FLOW_SEQ";
        }
      }

      comments[key] = {
        keyCommentBefore: item.key.commentBefore,
        valueCommentBefore: item.value?.commentBefore,
        comment: item.value?.comment,
        key: item.key.value,
        valueType: type,
      };

      commentsFromItems(item.value?.items || [], key);
    });

    return comments;
  }

  return commentsFromItems(doc?.contents?.items || []);
}

function addComments(doc, comments) {
  function addCommentsToItems(items = [], prefixKey) {
    items.forEach((item, index) => {
      if (!item?.key && !prefixKey) {
        return;
      }

      // needed for an item that has an array as value
      if (!item?.key && prefixKey) {
        addCommentsToItems(item?.items || [], `${prefixKey}.${index}`);
        return;
      }

      if (!YAML.isScalar(item?.key)) {
        item.key = new YAML.Scalar(item.key);
      }

      if (
        typeof item?.value === "string" ||
        typeof item?.value === "boolean" ||
        typeof item?.value === "number" ||
        item?.value === null
      ) {
        item.value = new YAML.Scalar(item.value);
      }

      const key = prefixKey
        ? `${prefixKey}.${item?.key?.value ? item.key.value : item.key}`
        : item.key.value;

      if (item.value?.items) {
        addCommentsToItems(item.value.items || [], key);
      }

      if (comments[key]?.keyCommentBefore) {
        item.key.keyCommentBefore = comments[key]?.keyCommentBefore;
        item.key.commentBefore = comments[key]?.keyCommentBefore;
      }
      if (comments[key]?.comment) {
        item.value.comment = comments[key]?.comment;
      }

      if (item.value && comments[key]?.valueCommentBefore) {
        item.value.commentBefore = comments[key].valueCommentBefore;
      }

      if (
        comments[key] &&
        (item.value?.type || item.value?.flow) &&
        comments[key].valueType &&
        comments[key].valueType !== item.value.type
      ) {
        item.value.type = comments[key].valueType;
        if (comments[key].valueType === "SEQ") {
          item.value.flow = false;
        }
      }

      if (item.value.type === "BLOCK_FOLDED") {
        item.value.type = "BLOCK_LITERAL";
      }
    });
  }

  addCommentsToItems(doc.contents.items);
}

// FIXME: this is a hack to prevent the
// yaml library from transforming an object full of null values
// into ? [key]
// this generates an UID that will be removed from the content of the
// toString() function of the parsedYaml document
const uid = uuid();
export function removeNullHax(content) {
  const regex = new RegExp(`\\s+${uid}: ${uid}`, "g");

  return content.replace(regex, "");
}

export function fixMultilineString(content) {
  return content.replace(/:(\s+)>\n/g, ":$1|\n").replace(/\n$/, "");
}

function checkForNull(obj) {
  const keys = Object.keys(obj || {});
  if (keys.length === 0) {
    return;
  }

  let allNull = true;

  keys.forEach((key) => {
    const value = obj[key];
    if (value !== null) {
      allNull = false;
    }
    if (value !== null && typeof value === "object") {
      checkForNull(value);
    }
  });

  if (allNull) {
    obj[uid] = uid;
  }
}

const yamlParseOptions = {
  strict: false,
  customTags: (tags) => {
    const intTag = tags.find((tag) => {
      return tag.tag === "tag:yaml.org,2002:int" && !tag.format;
    });
    if (intTag) {
      intTag.test = /^[-+]?[1-9](?:[0-9]+)?$/;
    }

    return [...tags];
  },
};

export function mergeYaml(base, ...overwrites) {
  if (overwrites.length === 0) {
    return base;
  }
  const fixedMultiline = fixMultilineString(base);

  let baseDoc = YAML.parseDocument(fixedMultiline, yamlParseOptions);
  if (baseDoc?.contents?.value === null) {
    baseDoc.contents = new YAML.Document({}).contents;
  }
  let comments = extractComments(baseDoc);

  const mergedDocument = overwrites.reduce((accumulator, overwrite) => {
    const overwriteDoc = YAML.parseDocument(overwrite, yamlParseOptions);
    const parsedOverwrite = overwriteDoc.toJS();
    const overwriteDocComments = extractComments(overwriteDoc);
    const keys = getTerminalKeys(parsedOverwrite, "", "~~~");

    keys.forEach((key) => {
      const paths = key.split("~~~").map((key) => {
        const isNumber = !isNaN(key);
        return isNumber ? parseInt(key) : key;
      });

      paths.forEach((path, index) => {
        const tracedPath = paths.slice(0, index + 1);
        const isNumber = !isNaN(paths[index + 1]);
        if (index === paths.length - 1) {
          return;
        }

        const value = accumulator.getIn(tracedPath);
        if (value === null || typeof value === "undefined") {
          if (isNumber) {
            accumulator.setIn(tracedPath, new YAML.YAMLSeq());
          } else {
            accumulator.setIn(tracedPath, new YAML.YAMLMap());
          }
        }
      });

      const value = overwriteDoc.getIn(paths);
      if (typeof value !== "undefined") {
        accumulator.setIn(paths, value);
      }
    });

    comments = merge(comments, overwriteDocComments);

    return merge(accumulator, parsedOverwrite);
  }, baseDoc);

  checkForNull(mergedDocument);

  addComments(mergedDocument, comments);

  const originalToString = mergedDocument.toString.bind(baseDoc);
  mergedDocument.toString = () => {
    const contents = originalToString({
      nullStr: "",
      lineWidth: 0,
    });

    const cleanContent = removeNullHax(contents);

    // handle overwrites that have multiple documents
    return overwrites.reduce((accumulator, overwrite) => {
      const parts = overwrite.split(`\n---`);
      parts.shift();

      return [accumulator.replace(/\n$/, ""), ...parts].join(`\n---`);
    }, cleanContent);
  };

  mergedDocument.commentBefore =
    mergedDocument.comment || mergedDocument.commentBefore;
  return mergedDocument;
}

export function addPackValueToYaml(values, overwrites) {
  const mergedDoc = mergeYaml(values, overwrites.trim());
  const packValuesIndex = mergedDoc.contents.items.findIndex(
    (item) => item.key.value === "pack" || item.key === "pack"
  );
  if (packValuesIndex !== -1 && packValuesIndex !== 0) {
    const packValues = mergedDoc.contents.items[packValuesIndex];
    mergedDoc.contents.items.splice(packValuesIndex, 1);
    mergedDoc.contents.items.splice(0, 0, packValues);
  }

  return mergedDoc;
}

export function extractInstallOrder(
  config = {},
  path = ["pack", "spectrocloud.com/install-priority"]
) {
  if (!config) {
    return undefined;
  }

  if (config?.installOrder !== undefined) {
    return config.installOrder;
  }

  try {
    const yamlDoc =
      YAML.parseDocument(config?.values || "") || new YAML.Document();
    return yamlDoc.getIn(path);
  } catch (e) {
    return null;
  }
}

// TODO: try use parseYAMLValues function
export function updateInstallOrder(
  { values = "", installOrder },
  path = ["pack", "spectrocloud.com/install-priority"]
) {
  try {
    const yamlDoc = YAML.parseDocument(values) || new YAML.Document();
    const hasInstallOrderValue = !!yamlDoc.getIn(path);

    if (
      hasInstallOrderValue &&
      (!installOrder || typeof installOrder === "undefined")
    ) {
      if (yamlDoc.get("pack")?.items?.length > 1) {
        yamlDoc.deleteIn(path);
      } else {
        if (yamlDoc.contents?.items?.length > 1) {
          yamlDoc.delete("pack");
        } else {
          return "";
        }
      }

      const yamlString = yamlDoc.toString();
      return fixMultilineString(yamlString);
    }

    if (!hasInstallOrderValue && !installOrder) {
      return values;
    }

    if (yamlDoc.getIn(path) !== installOrder) {
      const overwrite = `
      pack:
        spectrocloud.com/install-priority: "${installOrder}"
      `;

      const mergedDoc = addPackValueToYaml(values, overwrite);

      const yamlString = mergedDoc.toString();
      return fixMultilineString(yamlString);
    }

    return values;
  } catch (err) {
    return values;
  }
}

export function extractUbuntuAdvantagePresetOptions(values) {
  const paths = {
    token: `pro attach`,
    "esm-infra": `pro enable esm-infra`,
    "esm-apps": `pro enable esm-apps`,
    fips: `pro enable fips`,
    "fips-updates": `pro enable fips-updates`,
    livepatch: `pro enable livepatch`,
    cis: `pro enable cis`,
    "cc-eal": `pro enable cc-eal`,
    usg: `pro enable usg`,
  };
  try {
    const yamlDoc = YAML.parseDocument(values) || new YAML.Document();
    const commands = yamlDoc
      .getIn(["kubeadmconfig", "postKubeadmCommands"])
      .items.map((item) => item.value);

    const presets = Object.keys(paths).reduce(
      (acc, key) => ({
        ...acc,
        [key]: commands.find((command) => command.includes(paths[key])),
      }),
      {}
    );
    if (presets.token) {
      presets.token = presets.token.split("attach")?.[1]?.trim();
    }
    const enabled = Object.keys(presets).some((key) => !!presets[key]);
    return {
      ...presets,
      token: presets.token || "",
      enabled,
    };
  } catch (err) {
    return {};
  }
}

function getNonPresetValues(base) {
  let nonPresetValues = [];
  try {
    const parsedBaseDocument = YAML.parseDocument(base);
    const presets = parsedBaseDocument.getIn([
      "kubeadmconfig",
      "postKubeadmCommands",
    ]);
    if (presets?.items && presets?.items?.length) {
      nonPresetValues = presets?.items.filter(
        (val) =>
          !(
            val?.value?.startsWith(["pro enable"]) ||
            val?.value?.startsWith(["pro attach"])
          )
      );
    }
  } catch (err) {}
  return nonPresetValues;
}

function constructUbuntuProYaml(array) {
  return YAML.stringify({
    kubeadmconfig: {
      postKubeadmCommands: array,
    },
  });
}

function getUbuntuAdvantageYAMLContent(formData, base) {
  const nonPresetValues = getNonPresetValues(base);

  const commands = [
    formData.token && `pro attach ${formData.token}`,
    formData["esm-infra"] && `pro enable esm-infra`,
    formData["esm-apps"] && `pro enable esm-apps`,
    formData.fips && `pro enable fips`,
    formData["fips-updates"] && `pro enable fips-updates`,
    formData.livepatch && `pro enable livepatch`,
    formData.cis && `pro enable cis`,
    formData["cc-eal"] && `pro enable cc-eal`,
    formData.usg && `pro enable usg`,
  ].filter(Boolean);

  if (commands.length || nonPresetValues.length) {
    return constructUbuntuProYaml([...nonPresetValues, ...commands]);
  }

  return "";
}

export function getUbuntuAdvantagePresets({ value, field, base }, formData) {
  const updatedYaml = getUbuntuAdvantageYAMLContent(formData, base);

  if (field === "enabled" && value === false) {
    const nonPresetValues = getNonPresetValues(base);
    if (!nonPresetValues?.length) {
      return {
        remove: ["kubeadmconfig.postKubeadmCommands"],
        name: field,
        group: "ubuntuAdvantage",
      };
    }
    return {
      add: constructUbuntuProYaml(nonPresetValues),
      remove: ["kubeadmconfig.postKubeadmCommands"],
      name: field,
      group: "ubuntuAdvantage",
    };
  }

  return {
    add: updatedYaml,
    remove: ["kubeadmconfig.postKubeadmCommands"],
    name: field,
    group: "ubuntuAdvantage",
  };
}

export function extractManifestType(
  config = {},
  path = ["pack", "spectrocloud.com/manifest-type"]
) {
  if (!config?.values) {
    return undefined;
  }

  try {
    const yamlDoc =
      YAML.parseDocument(config?.values || "") || new YAML.Document();
    return yamlDoc.getIn(path);
  } catch (e) {
    return undefined;
  }
}

// TODO: try use parseYAMLValues function
export function updateManifestTypeValues({
  values = "",
  showType = true,
} = {}) {
  const path = ["pack", "spectrocloud.com/manifest-type"];
  try {
    const yamlDoc = YAML.parseDocument(values) || new YAML.Document();

    if (!showType) {
      if (yamlDoc.get("pack")?.items?.length > 1) {
        yamlDoc.deleteIn(path);
      } else {
        if (yamlDoc.contents.items?.length > 1) {
          yamlDoc.delete("pack");
        } else {
          return "";
        }
      }

      const yamlString = yamlDoc.toString();
      return fixMultilineString(yamlString);
    }

    if (yamlDoc.getIn(path) !== "vm") {
      const overwrite = `
      pack:
        spectrocloud.com/manifest-type: "vm"
    `;
      const mergedDoc = addPackValueToYaml(values, overwrite);
      const yamlString = mergedDoc.toString();
      return fixMultilineString(yamlString);
    }
  } catch (e) {
    return values;
  }
}

export function extractPackDisplayName(
  config = {},
  path = ["pack", "spectrocloud.com/display-name"]
) {
  if (config?.displayName !== undefined) {
    return config.displayName;
  }
  const yamlDoc =
    YAML.parseDocument(config?.values || "") || new YAML.Document();

  return yamlDoc.getIn(path);
}

export function extractQuotasFormUpdates({ path, values }) {
  const getKey = (keyPath) => (path ? `${path}.${keyPath}` : keyPath);

  try {
    const yamlDoc = YAML.parseDocument(values) || new YAML.Document();

    return Object.keys(QUOTAS_FIELDS).reduce((acc, key) => {
      QUOTAS_FIELDS[key].forEach((field) => {
        const formKey = getKey(field.value);
        const formValue = yamlDoc.getIn([
          "isolation",
          "resourceQuota",
          "quota",
          field.yamlKey,
        ]);

        if (formValue && parseFloat(formValue) >= 0) {
          Object.assign(acc, {
            [formKey]: parseFloat(formValue),
          });
        }
      });

      return acc;
    }, {});
  } catch (err) {
    return {};
  }
}

export function getQuotasYamlOverrides(values, formData = {}) {
  const commands = Object.keys(QUOTAS_FIELDS).reduce((acc, key) => {
    QUOTAS_FIELDS[key].forEach((field) => {
      const formValue = formData[field.value];

      if (parseFloat(formValue) >= 0) {
        const yamlValue =
          field.suffix === "Gi" ? `${formValue}Gi` : parseFloat(formValue);

        Object.assign(acc, {
          [field.yamlKey]: yamlValue,
        });
      }
    });

    return acc;
  }, {});

  return parseYAMLValues(values, {
    add: YAML.stringify({
      isolation: {
        resourceQuota: {
          quota: commands,
        },
      },
    }),
  });
}

export function getPair(yamlDoc, path = []) {
  let found = null;
  YAML.visit(yamlDoc, (key, node, parentPath) => {
    const visitedPath = parentPath
      .map((p, index) => {
        if (YAML.isPair(p)) {
          return p.key.value;
        }

        if (YAML.isSeq(p)) {
          if (index === parentPath.length - 1) {
            return undefined;
          }

          return p.items.indexOf(parentPath[index + 1]);
        }

        return undefined;
      })
      .filter((p) => p !== undefined);

    if (visitedPath.join(".") === path.join(".")) {
      const parent = parentPath[parentPath.length - 1];
      found = parent;
      return YAML.visit.BREAK;
    }
  });

  return found;
}
